/* الشبكة الرئيسية */
.projects__grid {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  grid-gap: 2em;
}

/* صندوق المشروع */
.project {
  background-color: var(--clr-bg-alt);
  padding: 1.5em;
  border-radius: 8px;
  box-shadow: var(--shadow);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s;
}

.project.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--clr-bg);
  z-index: 1000;
  padding: 0;
  overflow: hidden;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* العنوان والوصف */
.project__title {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: var(--clr-fg-alt);
}

.project__description {
  font-size: 1em;
  margin-bottom: 1em;
  color: var(--clr-fg);
}

/* صندوق الإطار */
.project__iframe-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Aspect ratio 16:9 */
  height: 0;
  overflow: hidden;
}

.project.fullscreen .project__iframe-container {
  width: 100%;
  height: 100%;
  padding-top: 0;
}

/* الإطار */
.project__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background-color: var(--clr-bg-alt);
}

/* زر التحكم */
.project__button {
  margin-top: 1rem;
  padding: 0.8rem 1.2rem;
  font-size: 1rem;
  border: 2px solid var(--clr-primary);
  background-color: transparent;
  color: var(--clr-primary);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
}

.project__button:hover {
  background-color: var(--clr-primary);
  color: var(--clr-bg);
  transform: translateY(-3px);
}

.project__button:focus {
  outline: none;
  transform: translateY(-1px);
}
